#root {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
}

// frame
.apr-frame {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    &::-webkit-scrollbar {
        background: #f5f6f7
    }
}

.frame-content {
    height: 100%;
    .ant-layout {
        min-height: 100%;
    }
}

// reset antd style
p {
    margin-top: auto;
    margin-bottom: auto;
}
